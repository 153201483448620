@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
}

.myfont {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.myfont1 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: 400; */
  font-style: normal;
}

.btn:hover {
  transition: 0.3s;
}

/* UIverse */


/* navbar wla button  */

.buttonNav {
  /* color: white; */
  padding: 24px 12px;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.buttonNav {
  --primary-color: #ffffff;
  --hovered-color: #c84747;
  position: relative;
  display: flex;
  font-weight: 400;
  font-size: 20px;
  gap: 0.5rem;
  align-items: center;
}

.buttonNav p {
  margin: 0;
  position: relative;
  font-size: 20px;
  color: var(--primary-color);
}

.buttonNav::after {
  position: absolute;
  content: "";
  width: 0;
  left: 7;
  bottom: 20px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.buttonNav p::before {
  position: absolute;
  /*   box-sizing: border-box; */
  content: "";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.buttonNav:hover::after {
  width: 80%;
}

.buttonNav:hover p::before {
  width: 80%;
}

.buttonNav:hover svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.buttonNav svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  /* width: 15px; */
  transition-delay: 0.2s;
}





/* new start */

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: 0.3s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: rgb(253, 255, 243);
  border-radius: 5px;
  transition-duration: 0.3s;
}

/* #checkbox:checked + .toggle .bars {
  margin-left: 13px;
} */

#checkbox:checked + .toggle #bar2 {
  transform: translateY(14px) rotate(60deg);
  margin-left: 0;
  transform-origin: right;
  transition-duration: 0.3s;
  z-index: 2;
}

#checkbox:checked + .toggle #bar1 {
  transform: translateY(28px) rotate(-60deg);
  transition-duration: 0.3s;
  transform-origin: left;
  z-index: 1;
}

#checkbox:checked + .toggle {
  transform: rotate(-90deg);
}
/* #checkbox:checked + .toggle #bar3 {
  transform: rotate(90deg);
  transition-duration: .3s;
  transform-origin:right;
} */



/* button start  */

.btn-conteiner {
  display: flex;
  justify-content: center;
  --color-text: white;
  --color-background: rgb(220 38 38);
  --color-outline: rgb(220 38 38);
  --color-shadow: #00000080;
}

.btn-content {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 14px;
  text-decoration: none;
  font-size: 16px;
  color: var(--color-text);
  background: var(--color-background);
  transition: 1s;
  border-radius: 7px;
  /* box-shadow: 0 0 0.2em 0 var(--color-background); */
}

.btn-content:hover,
.btn-content:focus {
  transition: 0.5s;
  -webkit-animation: btn-content 1s;
  animation: btn-content 1s;
  outline: 0.1em solid transparent;
  outline-offset: 0.2em;
  box-shadow: 0 0 0.4em 0 var(--color-background);
}

.btn-content .icon-arrow {
  transition: 0.5s;
  margin-right: 0px;
  transform: scale(0.6);
}

.btn-content:hover .icon-arrow {
  transition: 0.5s;
  margin-right: 10px;
}

.icon-arrow {
  width: 13px;
  margin-left: 2px;
  position: relative;
  /* top: 6%; */
}

/* SVG */
#arrow-icon-one {
  transition: 0.4s;
  transform: translateX(-60%);
}

#arrow-icon-two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.btn-content:hover #arrow-icon-three {
  animation: color_anim 1s infinite 0.2s;
}

.btn-content:hover #arrow-icon-one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.btn-content:hover #arrow-icon-two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */
@keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: var(--color-background);
  }

  100% {
    fill: white;
  }
}

/* Button animations */
@-webkit-keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

@keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

/* buttonLandingPage */
.buttonL {
  color: rgb(0, 0, 0);
  background: transparent;
  transition: ease-out 0.5s;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 rgb(0, 0, 0);
}

.buttonL:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 rgb(0, 0, 0);
}

.buttonL:active {
  transform: scale(0.9);
}

/* doosra Button  */

.btn1 {
  border: none;
  width: 15em;
  height: 5em;
  border-radius: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: #1c1a1a;
  cursor: pointer;
  transition: all 450ms ease-in-out;
}

.sparkle {
  fill: #aaaaaa;
  transition: all 800ms ease;
}

.text {
  font-weight: 600;
  color: black;
  font-size: medium;
}

.btn1:hover {
  background: linear-gradient(0deg, #f37c7c, #ea3f45);
  box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
    inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 4px rgba(255, 255, 255, 0.2), 0px 0px 180px 0px #ea3f45;
  transform: translateY(-2px);
}

.btn1:hover .text {
  color: black;
}

.btn1:hover .sparkle {
  fill: rgb(0, 0, 0);
  transform: scale(1.2);
}

/* Fade in  */

.fade {
  animation: fadeInAnimation ease 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }

}.fade1 {
  animation: fadeInAnimation1 ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation1 {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


